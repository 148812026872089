import React, { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Col, notification, Row, List, DatePicker, Button, Input, Popconfirm, Tooltip } from 'antd';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import 'rc-time-picker/assets/index.css';
import { useMediaQuery } from 'react-responsive';
import TextField from '@mui/material/TextField';

import { v1 } from 'uuid';

// csszz
import { css } from 'emotion';
import colors from '../../style/colors';
import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	DeleteOutlined,
	SaveOutlined,
	ExclamationCircleOutlined
} from '@ant-design/icons';

// ui-components
import DropDown from '../ui/DropDown';

const TimeRegistrationList = ({
	date = '',
	disabled = false,
	onChange = () => {},
	location = '',
	locationIFSRules = [],
	locationIsLoading = true,
	value = [],
	isForeman = false,
	isView = false
}) => {
	const moment = extendMoment(Moment);

	const [dataList, setDataList] = useState([]);
	const lang = useSelector(({ language }) => language.language);
	const isMobile = useMediaQuery({ query: '(max-width: 900px)' });
	const [showMobileHelpText, setshowMobileHelpText] = useState(false);

	// hard coded id as "value" since IFS did not provide an id for the types
	const timeRegistrationsTypes = [
		{ label: 'Work Time', value: 1, IfsValue: 'WorkTime' },
		{ label: 'Standby', value: 2, IfsValue: 'Standby' },
		{ label: 'Paid Rest', value: 3, IfsValue: 'PaidRest' },
		{ label: 'Driving Hours', value: 4, IfsValue: 'DrivingHours' },
		{ label: 'Heli Time Out', value: 5, IfsValue: 'HeliTimeOut' },
		{ label: 'Heli Time Home', value: 6, IfsValue: 'HeliTimeHome' },
		{ label: 'Waiting Hours', value: 7, IfsValue: 'WaitingHours' },

		// Boat Location
		{ label: 'Mobilization', value: 8, IfsValue: 'Mobilization' },
		{ label: 'Demobilization', value: 9, IfsValue: 'Demobilization' },
		{ label: 'Crew Change', value: 10, IfsValue: 'CrewChange' },
		{ label: 'WorkTime Boat', value: 11, IfsValue: 'NormalDay' },
		{ label: 'Waiting on weather', value: 12, IfsValue: 'WaitingWeather' }
	];

	const locationRules = locationIFSRules.filter(d => d.DabLocationValue == location)[0];

	const handleAddRegistration = val => {
		const toReturn = {
			id: v1(),
			type: val,
			startTime: '',
			endTime: '',
			customerDetail: '',
			customerNote: '',
			changeShift: false,
			shift: 'day',
			approvedByForeman: false,
			savedByBlueCollar: false,
			foremanChanges: {}
		};

		setDataList([...dataList, toReturn]);
		onChange([...dataList, toReturn]); // sort then parent form onChange
	};

	const locationRegistrationRules = location => {
		// filter time registration types based on location
		if (locationRules) return timeRegistrationsTypes.filter(d => JSON.parse(locationRules[d.IfsValue]));

		return [];
	};

	const sortTimeRegistrations = () => {
		const unsavedRegistrations = dataList.filter(d => !d.savedByBlueCollar);

		const sortedRegistrations = dataList //sort by milliseconds
			.filter(d => d.savedByBlueCollar)
			.sort((a, b) => {
				const diff =
					moment.range(moment(a.startTime, 'HH:mm:ss'), moment(a.endTime, 'HH:mm:ss')).start.valueOf() -
					moment.range(moment(b.startTime, 'HH:mm:ss'), moment(b.endTime, 'HH:mm:ss')).start.valueOf();
				return diff === 0
					? moment.range(moment(a.startTime, 'HH:mm:ss'), moment(a.endTime, 'HH:mm:ss')).end.valueOf() -
							moment.range(moment(b.startTime, 'HH:mm:ss'), moment(b.endTime, 'HH:mm:ss')).end.valueOf()
					: diff;
			});

		setDataList([...[...sortedRegistrations, ...unsavedRegistrations]]); // placed sorted saved time regs on the top then append unsaved regs
		return [...[...sortedRegistrations, ...unsavedRegistrations]]; // placed sorted saved time regs on the top then append unsaved regs
	};

	const handleDeleteRegistration = val => {
		const newDataList = dataList.filter(d => d.id !== val);

		// add logic if user deleted all regs , add registration dropdown should be empty

		setDataList(newDataList);
		onChange(newDataList);
	};

	const handleRegistrationSave = registration => {
		let { changeShift, startTime, endTime, shift, type } = registration;
		startTime = moment(startTime, 'HH:mm:ss');
		endTime = moment(endTime, 'HH:mm:ss');
		const { LocationType } = locationRules;

		// DAY AND NIGHT LOGIC SOON

		if (!startTime.isValid() || !endTime.isValid()) {
			return notification.error({
				duration: 7,
				message: 'FAILED',
				description: 'Invalid time range'
			});
		}

		// STANDBY TYPE VALIDATIONS
		if (type === 2 && LocationType === 'ONSHORE') {
			const allSavedStandby = dataList.filter(d => d.savedByBlueCollar && d.type === 2);
			let totalHoursStandby = 0;

			//if standby is more than 12 hours
			if (allSavedStandby.length) {
				allSavedStandby.forEach(d => {
					// 1. add hours
					totalHoursStandby += moment(d.endTime, 'HH:mm').diff(moment(d.startTime, 'HH:mm'), 'hours');
					//2. add minute differencials per registration
					totalHoursStandby +=
						(parseInt(
							moment.utc(moment(d.endTime, 'HH:mm').diff(moment(d.startTime, 'HH:mm'))).format('mm')
						) /
							60) *
						1;
				});
			}

			//3. finally add the incoming registration's hours and minutes
			totalHoursStandby += endTime.diff(startTime, 'hours');
			totalHoursStandby += (parseInt(moment.utc(endTime.diff(startTime)).format('mm')) / 60) * 1;

			if (totalHoursStandby > 12) {
				return notification.error({
					duration: 7,
					message: 'FAILED',
					description: 'It is not possible to register more than 12 hours of standby for one day'
				});
			}
		}

		// if standby is 7PM onwards and not Onshore
		if (
			LocationType === 'OFFSHORE' &&
			type === 2 &&
			(endTime.isSameOrBefore(startTime) ||
				!startTime.isBefore(moment('19:15', 'HH:mmm')) ||
				!endTime.isBefore(moment('19:15', 'HH:mmm')))
		) {
			return notification.error({
				duration: 7,
				message: 'FAILED',
				description: 'It is not possible to register standby past 7PM on Offshore'
			});
		}

		//get all saved registrations
		const savedTimeRegistrations = dataList.filter(d => d.savedByBlueCollar); // filter out saved
		if (savedTimeRegistrations.length === 0) return true; //no saved time reg

		let isTimeValid = true;

		savedTimeRegistrations.every(d => {
			//loop the saved time regs then check if the saving time range overlaps

			// const toSaveDate = moment.range(moment(startTime, "HH:mm:ss"), moment(endTime, "HH:mm:ss"));
			// const savedDate = moment.range(moment(d.startTime, "HH:mm:ss"), moment(d.endTime, "HH:mm:ss"));

			const currentSavedStartDate = moment(d.startTime, 'HH:mm:ss');
			const incomingStartDate = moment(startTime);

			//  if end time is before start time (overnight) , add 1 day
			const currentSavedEndDate = moment(d.endTime, 'HH:mm:ss').isSameOrBefore(currentSavedStartDate)
				? moment(d.endTime, 'HH:mm:ss').add(1, 'day')
				: moment(d.endTime, 'HH:mm:ss');
			const incomingEndDate = moment(endTime).isSameOrBefore(incomingStartDate)
				? moment(endTime).add(1, 'day')
				: moment(endTime);

			const toSaveDateRange = moment.range(incomingStartDate.format(), incomingEndDate.format());
			const savedDateRange = moment.range(
				currentSavedStartDate.format('YYYY-MM-DDTHH:mm:ssZ'),
				currentSavedEndDate.format('YYYY-MM-DDTHH:mm:ssZ')
			);

			//check if time overlaps
			if (toSaveDateRange.overlaps(savedDateRange)) {
				isTimeValid = false;
				return notification.error({
					duration: 7,
					message: 'FAILED',
					description: 'This time range is overlapping with other registrations.'
				});
			}
			return true;
		});
		return isTimeValid;
	};

	const handleRegistrationChanges = (field, registration, val) => {
		const { id } = registration;

		// dynamically changes object values by key

		let newDataList = dataList;
		newDataList.map((d, i) => {
			if (d.id === id) {
				newDataList[i] = { ...newDataList[i], [field]: val };
			}
			return newDataList;
		});

		setDataList([...newDataList]); // workaround to re-render
		onChange(sortTimeRegistrations()); // sort then parent form onChange
	};

	//load registrations from saved form
	useEffect(() => {
		setDataList(value);
	}, [value]);

	// remove irrelevant time regs when changing Location
	useEffect(() => {
		if (dataList.length !== 0 && isForeman === false) {
			// const newDataList = dataList.filter((d) => locationRegistrationRules(location).includes(d.type));
			const newDataList = dataList
				.filter(d =>
					locationRegistrationRules(location)
						.map(d => d.value)
						.includes(d.type)
				)
				.map(d => ({ ...d, ...(d.type === 2 && { savedByBlueCollar: false }) }));

			setDataList(newDataList);
			onChange(newDataList);
			notification.warning({
				duration: 7,
				message: 'INFO',
				description: lang.timeRegistrationListLocationChange
			});
		}

		// primitive workaround , in foreman forms, the location is always changing so the date should be conditioned if the change of location is triggered by the form action or by change of record
		if (isForeman) {
			const prevDate = localStorage.getItem('semcotime-foreman-last-form-date')
				? JSON.parse(localStorage.getItem('semcotime-foreman-last-form-date'))
				: date;

			if (prevDate === date) {
				const newDataList = dataList
					.filter(d =>
						locationRegistrationRules(location)
							.map(d => d.value)
							.includes(d.type)
					)
					.map(d => ({ ...d, ...(d.type === 2 && { savedByBlueCollar: false, approvedByForeman: false }) }));

				onChange(newDataList);
				notification.warning({
					duration: 1,
					message: 'INFO',
					description: 'Due to Location change, irrelevant time registration(s) has been removed.'
				});
			}

			localStorage.setItem('semcotime-foreman-last-form-date', JSON.stringify(date));
		}
	}, [location]);

	useEffect(() => {
		if (showMobileHelpText) {
			notification.info({
				duration: 0,
				description: 'Please round up to the nearest half hour'
			});
		} else {
			notification.destroy();
		}
	}, [showMobileHelpText]);

	const checkShift = (startTime, endTime) => {
		const momentStartTime = moment(startTime, 'HH:mm:00');
		const momentEndtime = moment(endTime, 'HH:mm:00');

		if (momentEndtime.isSameOrBefore(momentStartTime)) return 'night';

		return 'day';
	};

	return (
		<div className={componentStyles(isForeman)}>
			{!isView && (
				<>
					<p style={{ fontSize: '18px' }}>{lang.timeRegistrationListLabel}</p>
					<DropDown
						disabled={disabled}
						loading={locationIsLoading}
						style={{ width: '50%' }}
						name="timeRegistrations"
						onChange={e => handleAddRegistration(e.target.value)}
						options={locationRegistrationRules(location)}
						placeholder={lang.timeRegistrationListPlaceholder}
						value={null}
					/>
				</>
			)}

			{dataList.length !== 0 && (
				<>
					<div className="registrationSeparator"></div>
					<List
						size="large"
						bordered
						// className={componentStyles(isForeman)}
						dataSource={dataList}
						renderItem={(item, index) => (
							<List.Item
								key={item.id}
								rowKey={item.id}
								actions={
									isView
										? []
										: [
												isForeman && (
													<Button
														size="small"
														className="listButtons"
														disabled={disabled || !item.savedByBlueCollar}
														onClick={() => {
															handleRegistrationChanges(
																'approvedByForeman',
																item,
																!item.approvedByForeman
															);
														}}
														icon={
															!item.approvedByForeman ? (
																<CheckCircleOutlined />
															) : (
																<CloseCircleOutlined />
															)
														}>
														{item.approvedByForeman
															? lang.timeRegistrationListUnapprove
															: lang.timeRegistrationListApprove}
													</Button>
												),
												<Button
													size="small"
													disabled={disabled || item.approvedByForeman}
													className="listButtons"
													onClick={() => {
														if (item.savedByBlueCollar) {
															//if user want to edit a saved time registration
															handleRegistrationChanges(
																'savedByBlueCollar',
																item,
																!item.savedByBlueCollar
															);
														} else {
															if (handleRegistrationSave(item)) {
																handleRegistrationChanges(
																	'savedByBlueCollar',
																	item,
																	!item.savedByBlueCollar
																);
															}
														}
													}}
													icon={<SaveOutlined />}>
													{item.savedByBlueCollar
														? lang.timeRegistrationListEdit
														: lang.timeRegistrationListSave}
												</Button>,
												<Popconfirm
													title="Are you sure to delete this time registration?"
													placement="leftBottom"
													onConfirm={() => handleDeleteRegistration(item.id)}>
													<Button
														size="small"
														disabled={disabled}
														className="listButtons"
														icon={<DeleteOutlined />}
													/>
												</Popconfirm>
										  ]
								}>
								<Row gutter={[8, 8]}>
									{item.changeShift && (item.type === 1 || item.type === 11) && isForeman && (
										<Col span={24}>
											<p style={{ color: 'orange' }}>
												<ExclamationCircleOutlined /> Manual Change Shift
											</p>
										</Col>
									)}

									<Col span={24}>
										<p style={{ fontWeight: 'bold' }}>
											{item.type === 'workTime'
												? 'Work Time'
												: timeRegistrationsTypes.filter(d => d.value === item.type)[0].label}
										</p>
									</Col>
									<Col span={32}>
										<Row gutter={[20, 20]} style={{ marginBottom: '10px' }}>
											<Tooltip
												placement="leftTop"
												zIndex={
													isMobile ||
													item.savedByBlueCollar ||
													item.approvedByForeman ||
													![4, 5, 6].includes(item.type)
														? -1
														: 99999
												}
												title={'Please round up to the nearest half hour'}>
												<Col>
													<DatePicker
														// onFocus={() => setshowMobileHelpText(isMobile && [4, 5, 6].includes(item.type))}
														onBlur={() => setshowMobileHelpText(false)}
														format={'HH:mm'}
														minuteStep={[4, 5, 6].includes(item.type) ? 30 : 15}
														disabled={item.savedByBlueCollar}
														getPopupContainer={trigger => trigger.parentElement}
														picker={'time'}
														onSelect={date => {
															handleRegistrationChanges(
																'startTime',
																item,
																date.format('HH:mm')
															);

															if ([1, 10, 11, 12].includes(item.type)) {
																// check shift only if worktime type
																handleRegistrationChanges(
																	'shift',
																	item,
																	checkShift(date.format('HH:mm:00'), item.endTime)
																);
															}
														}}
														onChange={(obj, date) => {
															handleRegistrationChanges('startTime', item, date);

															if ([1, 10, 11, 12].includes(item.type)) {
																// check shift only if worktime type
																handleRegistrationChanges(
																	'shift',
																	item,
																	checkShift(date, item.endTime)
																);
															}
														}}
														placeholder={lang.timeRegistrationListFrom}
														value={item.startTime ? moment(item.startTime, 'HH:mm') : ''}
													/>
												</Col>
											</Tooltip>
											<Tooltip
												placement="rightTop"
												zIndex={
													isMobile ||
													item.savedByBlueCollar ||
													item.approvedByForeman ||
													![4, 5, 6].includes(item.type)
														? -1
														: 99999
												}
												title={'Please round up to the nearest half hour'}>
												<Col>
													<DatePicker
														// onFocus={() => setshowMobileHelpText(isMobile && [4, 5, 6].includes(item.type))}
														onBlur={() => setshowMobileHelpText(false)}
														format={'HH:mm'}
														minuteStep={[4, 5, 6].includes(item.type) ? 30 : 15}
														disabled={item.savedByBlueCollar}
														getPopupContainer={trigger => trigger.parentElement}
														picker={'time'}
														onSelect={date => {
															handleRegistrationChanges(
																'endTime',
																item,
																date.format('HH:mm')
															);

															if ([1, 10, 11, 12].includes(item.type)) {
																// check shift only if worktime type
																handleRegistrationChanges(
																	'shift',
																	item,
																	checkShift(item.startTime, date.format('HH:mm:00'))
																);
															}
														}}
														onChange={(obj, date) => {
															handleRegistrationChanges('endTime', item, date);

															if ([1, 10, 11, 12].includes(item.type)) {
																// check shift only if worktime type
																handleRegistrationChanges(
																	'shift',
																	item,
																	checkShift(item.startTime, date)
																);
															}
														}}
														placeholder={lang.timeRegistrationListTo}
														value={item.endTime ? moment(item.endTime, 'HH:mm') : ''}
													/>
												</Col>
											</Tooltip>
											{[1, 10, 11, 12].includes(item.type) && (
												<>
													<Col>
														<div style={{ paddingTop: '5px' }}>
															<DropDown
																// loading={}
																allowClear={false}
																disabled={!item.changeShift || item.savedByBlueCollar}
																name="shift"
																size={'small'}
																onChange={e =>
																	handleRegistrationChanges(
																		'shift',
																		item,
																		e.target.value
																	)
																}
																value={item.shift}
																options={[
																	{
																		label: lang.timeRegistrationListDay,
																		value: 'day'
																	},
																	{
																		label: lang.timeRegistrationListNight,
																		value: 'night'
																	}
																]}
																placeholder="Shift"
																{...(isMobile ? { style: { width: '100%' } } : {})}
															/>
														</div>
													</Col>
													<Col>
														<Checkbox
															style={{ paddingTop: '5px', paddingBottom: '0px' }}
															checked={item.changeShift}
															disabled={item.savedByBlueCollar}
															onClick={() => {
																handleRegistrationChanges(
																	'changeShift',
																	item,
																	!item.changeShift
																);
																handleRegistrationChanges(
																	'shift',
																	item,
																	checkShift(item.startTime)
																);
															}}
															name="changeShift">
															{lang.timeRegistrationListChangeShift}
														</Checkbox>
													</Col>
												</>
											)}
										</Row>

										<Row gutter={[8, 8]}>
											{[1, 11].includes(item.type) && (
												<Col>
													<TextField
														size="small"
														label="Customer Details"
														variant="outlined"
														value={item.customerDetail}
														disabled={item.savedByBlueCollar}
														onChange={e =>
															handleRegistrationChanges(
																'customerDetail',
																item,
																e.target.value
															)
														}
													/>
												</Col>
											)}
											{[1, 2, 3, 7, 8, 9, 10, 11, 12].includes(item.type) && (
												<Col>
													<TextField
														size="small"
														label={lang.timeRegistrationFormRemark}
														variant="outlined"
														value={item.customerNote}
														disabled={item.savedByBlueCollar}
														onChange={e =>
															handleRegistrationChanges(
																'customerNote',
																item,
																e.target.value
															)
														}
													/>
												</Col>
											)}
										</Row>
									</Col>
								</Row>
							</List.Item>
						)}
					/>
				</>
			)}
		</div>
	);
};

const componentStyles = isForeman => css`
	@media screen and (max-width: 576px) {
		.ant-list-item-action {
			padding-top: 15px;
			margin-left: ${isForeman ? '16vw' : '50vw'} !important;
		}
	}
	.seperator {
		background-color: ${colors.lightGrey};
		height: 1px;
		margin: 1.5rem -0.75rem 1rem -0.75rem;
	}

	.registrationSeparator {
		padding: 10px;
	}

	.ant-picker-panel-container {
		.ant-picker-time-panel-column {
			overflow-y: auto !important;
		}
		.ant-picker-time-panel-column::-webkit-scrollbar {
			-webkit-appearance: none;
		}

		.ant-picker-time-panel-column::-webkit-scrollbar:vertical {
			width: 11px;
		}

		.ant-picker-time-panel-column::-webkit-scrollbar:horizontal {
			height: 11px;
		}

		.ant-picker-time-panel-column::-webkit-scrollbar-thumb {
			border-radius: 8px;
			border: 2px solid white; /* should match background, can't be transparent */
			background-color: rgba(0, 0, 0, 0.5);
		}
	}
`;

export default memo(TimeRegistrationList);
